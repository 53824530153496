import { ref, computed } from 'vue'
import { getCache } from './cache'
import { fetch } from './sys'
import { useRouter } from 'vue-router'

export const isMarketPicked = ref(!!getCache('currentMarketId'))
export const currentMarketId = ref(getCache('currentMarketId') || '')

export function handleLoginByAccount() {}
export function handleLoginByCode(code) {
  localStorage['Bm-Member-Token'] = code
  fetch.post('/boom-center-admin-service/app/commercialPlazaUserInfo/loginByMemberToken').then(res => {
    if (res.accessToken) {
      localStorage['X-Commercial-Access-Token'] = res.accessToken
    }
  })
}
// 登出
export function logout() {
  const router = useRouter()
  sessionStorage.clear()
  router.replace('/account/login')
}
