<template lang="pug">
.page
  el-card(header="营业周期")
    div(style="padding-bottom: 10px;") 
      el-checkbox(border v-model="allCheck" @change="handleAllCheck") 全选
    el-checkbox-group(v-model="yearOfMonthList" size="small")
      el-checkbox(v-for="i in monthList" :label="i.value" border) {{i.name}}
  el-card(header="营业日")
    .ruleList
      el-form.ruleItem(v-for="(i,n) in normalOpeningHoursList" label-width="5em" :label-position="isMobile?'top':'right'")
        el-form-item(label="营业日")
          .closeBox(@click="closeNormalOpeningHours(n)")
            el-icon
              CloseBold
          el-checkbox-group(v-model="i.dayOfWeekList" size="small")
            el-checkbox(v-for="i in weekList" :label="i.value" border :disabled="isDayDisabled(n,i.value)") {{i.name}}
        el-form-item(label="时段设置")
          .timeRuleList
            el-form.timeRuleItem(v-for="(j,m) in i.timeIntervals" label-width="7em" :label-position="isMobile?'top':'right'")
              el-form-item(label="营业开始时间")
                el-time-picker(v-model="j.startTime" :picker-options="{format: 'HH:mm'}" value-format="HH:mm" placeholder="选择营业开始时间" style="width: 200px;" size="small")
              el-form-item(label="营业时长")
                el-input-number(v-model="j.hoursDuration" controls-position="right" style="width: 100px;" size="small")
                .label 时
                el-input-number(v-model="j.minutesDuration" controls-position="right" style="width: 100px;" size="small")
                .label 分
              .actionBar
                el-button(:icon="Delete" circle plain type="danger" @click="handleDeleteTimeRuleItem(n,m)")
            .timeRuleItem.addButton(@click="handleAddTimeRuleItem(n)")
              el-icon
                Plus
              span 添加时段
      .ruleItem.addButton(@click="handleAddRuleItem")
        el-icon
          Plus
        span 添加营业日
  el-card(header="特殊营业时间")
    .ruleList
      el-form.ruleItem(v-for="(i,n) in specialOpeningHoursList" label-width="7em" :label-position="isMobile?'top':'right'")
        el-form-item(label="特殊营业日期")
          .closeBox(@click="closeSpecialOpeningHours(n)")
              el-icon
                CloseBold
          el-date-picker(v-model="i.specialDay" value-format="x" format="YYYY-MM-DD" type="date" placeholder="选择日期")
        el-form-item(label="是否营业")
          el-radio-group(v-model="i.isService")
            el-radio(:label="1") 是
            el-radio(:label="0") 否
        el-form-item(label="时段设置" v-if="i.isService===1")
          .timeRuleList
            el-form.timeRuleItem(v-for="(j,m) in i.timeIntervals" label-width="7em" :label-position="isMobile?'top':'right'")
              el-form-item(label="营业开始时间")
                el-time-picker(v-model="j.startTime" :picker-options="{format: 'HH:mm'}" value-format="HH:mm" placeholder="选择营业开始时间" style="width: 200px;" size="small")
              el-form-item(label="营业时长")
                el-input-number(v-model="j.hoursDuration" controls-position="right" style="width: 100px;" size="small")
                .label 时
                el-input-number(v-model="j.minutesDuration" controls-position="right" style="width: 100px;" size="small")
                .label 分
              .actionBar
                el-button(:icon="Delete" circle plain type="danger" @click="handleDeleteSpecialTimeRuleItem(n,m)")
            .timeRuleItem.addButton(@click="handleAddSpecialTimeRuleItem(n)")
              el-icon
                Plus
              span 添加时段
      .ruleItem.addButton(@click="handleAddSepecialRuleItem")
        el-icon
          Plus
        span 添加特殊营业日期
  el-card
    .actionBar
      el-button(type="info" @click="isShowOpenTimePreview=true") 预览
      el-button(type="primary" style="width:100%" :disabled="!!errorMessage" @click="handleSubmit") {{errorMessage||'保存'}}

el-dialog(v-model="isShowOpenTimePreview" title="营业时间预览")
  comOpenTimePreview(:yearOfMonthList="yearOfMonthList" :normalOpeningHoursList="normalOpeningHoursList" :specialOpeningHoursList="specialOpeningHoursList")
</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { Plus, Delete, CloseBold } from '@element-plus/icons-vue'

import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'
import { monthList, weekList } from '@/util/dict'
import { formatDate } from '@/util/format'

import comOpenTimePreview from './__com__/openTimePreview.vue'

const { fetch, message, router } = inject('global')

const isShowOpenTimePreview = ref(false)

const allCheck = computed(() => {
  return yearOfMonthList.value.length == 12
})
const form = ref({})
const yearOfMonthList = ref([])
const normalOpeningHoursList = ref([])
const specialOpeningHoursList = ref([])

function handleAllCheck() {
  yearOfMonthList.value = allCheck.value ? [] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
}

function getData() {
  fetch
    .get(`/boom-center-product-service/businessAdmin/commercialPlazaAssistant/businessHours/${currentMarketId.value}`)
    .then(res => {
      form.value = res
      yearOfMonthList.value = res.yearOfMonthList
      normalOpeningHoursList.value = getNormalOpeningHoursList(res.normalOpeningHoursList)
      specialOpeningHoursList.value = res.specialOpeningHoursList
    })
    .catch(err => {
      message.error(err?.msg || err)
    })
}

function getNormalOpeningHoursList(list) {
  let result = []
  list.forEach(i => {
    const isExist = result.find(j => j.dayOfWeekList.includes(i.dayOfWeek))
    if (!isExist) {
      const isSameIndex = result.findIndex(j => JSON.stringify(j.timeIntervals) === JSON.stringify(i.timeIntervals))
      if (isSameIndex >= 0) {
        result[isSameIndex].dayOfWeekList.push(i.dayOfWeek)
      } else {
        result.push({ dayOfWeekList: [i.dayOfWeek], timeIntervals: i.timeIntervals })
      }
    }
  })
  return result
}

function handleDeleteTimeRuleItem(n, m) {
  normalOpeningHoursList.value[n].timeIntervals.splice(m, 1)
}
function handleAddTimeRuleItem(n) {
  normalOpeningHoursList.value[n].timeIntervals.push({ startTime: '08:00', hoursDuration: 12, minutesDuration: 0 })
}
function handleAddRuleItem() {
  normalOpeningHoursList.value.push({
    dayOfWeekList: [],
    timeIntervals: []
  })
}
function handleDeleteSpecialTimeRuleItem(n, m) {
  specialOpeningHoursList.value[n].timeIntervals.splice(m, 1)
}
function handleAddSpecialTimeRuleItem(n) {
  specialOpeningHoursList.value[n].timeIntervals.push({ startTime: '08:00', hoursDuration: 12, minutesDuration: 0 })
}
function handleAddSepecialRuleItem() {
  specialOpeningHoursList.value.push({
    specialDay: '',
    isService: 1,
    timeIntervals: []
  })
}
function isDayDisabled(n, val) {
  const allDayList = normalOpeningHoursList.value.map(i => i.dayOfWeekList).reduce((t, i) => (t = t.concat(i)), [])
  const otherDayList = allDayList.filter(i => !normalOpeningHoursList.value[n].dayOfWeekList.includes(i))
  return otherDayList.includes(val)
}

function getNormalOpeningHoursListForSubmit() {
  let result = []
  normalOpeningHoursList.value.forEach(i => {
    if (i?.dayOfWeekList?.length) {
      i.dayOfWeekList.forEach(j => {
        result.push({
          dayOfWeek: j,
          timeIntervals: i.timeIntervals
        })
      })
    }
  })
  return result
}

// 删除营业日
function closeNormalOpeningHours(index) {
  normalOpeningHoursList.value.splice(index, 1)
}

// 删除特殊营业时间
function closeSpecialOpeningHours(index) {
  specialOpeningHoursList.value.splice(index, 1)
}

const errorMessage = computed(() => {
  if (!yearOfMonthList.value?.length) return '营业周期不能为空'
  if (!normalOpeningHoursList.value?.length) return '营业日不能为空'
  return ''
})
function handleSubmit() {
  if (errorMessage.value) {
    message.warning(errorMessage.value)
  } else {
    console.log(
      JSON.stringify({
        ...form.value,
        yearOfMonthList: yearOfMonthList.value,
        normalOpeningHoursList: getNormalOpeningHoursListForSubmit(),
        specialOpeningHoursList: specialOpeningHoursList.value.map(s => {
          s.specialDay = formatDate(s.specialDay)
          return s
        })
      })
    )
    fetch
      .post(
        `/boom-center-product-service/businessAdmin/commercialPlazaAssistant/businessHours/${currentMarketId.value}`,
        {
          ...form.value,
          yearOfMonthList: yearOfMonthList.value,
          normalOpeningHoursList: getNormalOpeningHoursListForSubmit(),
          specialOpeningHoursList: specialOpeningHoursList.value.map(s => {
            s.specialDay = formatDate(s.specialDay)
            return s
          })
        }
      )
      .then(res => {
        message.success('保存成功，等待审核')
        router.go(-1)
      })
      .catch(err => {
        message.error(err?.msg || err)
      })
  }
}

onMounted(() => {
  getData()
})
</script>

<style lang="less" scoped>
.ruleItem {
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  margin: 10px;
}
.timeRuleList {
  display: flex;
  flex-wrap: wrap;
  .timeRuleItem {
    .trans;
    border: 1px solid var(--color-blue);
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    .label {
      flex-shrink: 0;
      padding: 0 5px;
    }
    .actionBar {
      display: none;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    &:hover {
      .actionBar {
        display: block;
      }
    }
  }
}
&.addButton {
  .flexCenter();
  flex-direction: column;
  min-width: 100px;
  min-height: 100px;
  font-size: 40px;
  cursor: pointer;
  span {
    font-size: 14px;
  }
  &:hover {
    color: var(--color-green);
    border: 1px solid var(--color-green);
  }
}
.actionBar {
  display: flex;
  align-items: center;
}
.closeBox {
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: -32px;
  background-color: #eee;
  border-radius: 5px;
}
</style>
