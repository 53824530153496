import { monthList, weekList } from '@/util/dict'

export function formatMonthName(val) {
  return monthList.find(i => i.value == val)?.name
}
export function formatWeekName(val) {
  return weekList.find(i => i.value == val)?.name
}
export function formatFddStatus(val) {
  if (!val) return '未知'
  return (
    {
      notActive: '未激活',
      notVerify: '未认证',
      success: '成功',
      denied: '拒绝',
      pending: '处理中'
    }[val] || val
  )
}
export function formatFddStatusForTagType(val) {
  if (!val) return 'info'
  return (
    {
      notActive: 'warning',
      notVerify: 'warning',
      success: 'success',
      denied: 'danger',
      pending: ''
    }[val] || ''
  )
}

export function formatDate(val) {
  if (!val) return '-'
  let date = new Date(val)
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  return year + '-' + month + '-' + day
}
