export const monthList = [
  { name: '一月', value: 1 },
  { name: '二月', value: 2 },
  { name: '三月', value: 3 },
  { name: '四月', value: 4 },
  { name: '五月', value: 5 },
  { name: '六月', value: 6 },
  { name: '七月', value: 7 },
  { name: '八月', value: 8 },
  { name: '九月', value: 9 },
  { name: '十月', value: 10 },
  { name: '十一月', value: 11 },
  { name: '十二月', value: 12 }
]
export const weekList = [
  { name: '周一', value: 1 },
  { name: '周二', value: 2 },
  { name: '周三', value: 3 },
  { name: '周四', value: 4 },
  { name: '周五', value: 5 },
  { name: '周六', value: 6 },
  { name: '周日', value: 7 }
]
