// 默认缓存过期时间，5分钟
const defaultExpireTime = 1000 * 60 * 5

// 更新缓存内容（续签新过期时间）
export function updateCache(k, v, option = { expireTime: defaultExpireTime }) {
  const name = getCacheName(k)
  if (!name) return
  sessionStorage[name] = JSON.stringify(combineCacheValue(v, option.expireTime))
}

// 获取缓存内容（已过期会释放）
export function getCache(k) {
  const name = getCacheName(k)
  if (!name) return
  const valString = sessionStorage[name]
  if (!valString) return
  const valObject = JSON.parse(valString)
  if (!valObject.t) return valObject.v
  if (new Date(valObject.t) < new Date()) {
    clearCache(k)
    return
  }
  return valObject.v
}

// 清除缓存
export function clearCache(k) {
  const name = getCacheName(k)
  if (!name) return
  sessionStorage.removeItem(name)
}

function getCacheName(k) {
  if (!k) return
  return `bm-market_${k}`
}
function combineCacheValue(v, expireTime) {
  return {
    v: v,
    t: getCacheTime(expireTime)
  }
}
function getCacheTime(expireTime) {
  if (!expireTime) {
    return ''
  }
  return new Date(Date.now() + expireTime)
}
