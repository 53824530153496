<template lang="pug">
.page
  el-form(label-position="top")
    el-form-item(label="营业周期")
      el-tag(v-for="i in yearOfMonthList" style="margin:5px") {{formatMonthName(i)}}
    el-form-item(label="营业日")
      .ruleList
        .ruleItem(v-for="i in normalOpeningHoursList")
          el-tag(v-for="j in i.dayOfWeekList" style="margin:5px" size="large") {{formatWeekName(j)}}
          .time(v-for="j in i.timeIntervals")
            .timeItem.start 
              .value {{j.startTime}}
              .label 开始时间
            .timeItem.openTime 
              .value {{j.hoursDuration}} 时 {{j.minutesDuration}} 分
              .label 营业时长
            .timeItem.end 
              .value {{getEndTime(j)}}
              .label 结束时间
    el-form-item(label="特殊营业日")
      .ruleList
        .ruleItem(v-for="i in specialOpeningHoursList")
          el-tag(size="large") {{getSpecialDayName(i.specialDay)}}
          .time(v-for="j in i.timeIntervals")
            .timeItem.start 
              .value {{j.startTime}}
              .label 开始时间
            .timeItem.openTime 
              .value {{j.hoursDuration}} 时 {{j.minutesDuration}} 分
              .label 营业时长
            .timeItem.end 
              .value {{getEndTime(j)}}
              .label 结束时间

</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { formatMonthName, formatWeekName } from '@/util/format'

const props = defineProps({
  yearOfMonthList: {
    type: Array,
    default: () => []
  },
  normalOpeningHoursList: {
    type: Array,
    default: () => []
  },
  specialOpeningHoursList: {
    type: Array,
    default: () => []
  }
})

const normalOpeningHoursList = computed(() => {
  if (props.normalOpeningHoursList?.[0]?.dayOfWeekList) {
    return props.normalOpeningHoursList
  }
  return getNormalOpeningHoursList(props.normalOpeningHoursList)
})

function getNormalOpeningHoursList(list) {
  let result = []
  list.forEach(i => {
    const isExist = result.find(j => j.dayOfWeekList.includes(i.dayOfWeek))
    if (!isExist) {
      const isSameIndex = result.findIndex(j => JSON.stringify(j.timeIntervals) === JSON.stringify(i.timeIntervals))
      if (isSameIndex >= 0) {
        result[isSameIndex].dayOfWeekList.push(i.dayOfWeek)
      } else {
        result.push({ dayOfWeekList: [i.dayOfWeek], timeIntervals: i.timeIntervals })
      }
    }
  })
  return result
}

function getSpecialDayName(val) {
  return new Date(val).toLocaleString().slice(0, 8)
}

function getEndTime(item) {
  if (!item?.startTime) return ''
  let t =
    new Date('2022 ' + item.startTime).getTime() +
    (item.hoursDuration || 0) * 1000 * 60 * 60 +
    (item.minutesDuration || 0) * 1000 * 60
  return new Date(t).toLocaleString().slice(9, 14)
}
</script>

<style lang="less" scoped>
.ruleItem {
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  margin: 10px;
  text-align: center;
  .time {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .timeItem {
      --color: var(--color-blue);
      border-radius: 10px;
      border: 1px solid var(--color);
      overflow: hidden;
      line-height: 1.2;
      width: 140px;
      .value {
        color: var(--color);
        font-size: 16px;
        line-height: 30px;
      }
      .label {
        font-size: 14px;
        line-height: 24px;
        background: var(--color);
        color: white;
      }
      &.start {
        --color: var(--color-orange);
      }
      &.openTime {
        --color: var(--color-blue);
      }
      &.end {
        --color: var(--color-grey);
      }
    }
    .openTime {
      margin: 0 20px;
    }
  }
}
</style>
